<template>
    <div>
      <base-header class="pb-6">
        <b-row class="py-4">
          <b-col lg="6" cols="7" >
            <h6 class="h2 text-white d-inline-block mb-0"><i class="ni ni-support-16"></i> Panduan Sparing</h6>
          </b-col>
        </b-row>
      </base-header>
  
      <b-container fluid class="mt--6">
        <b-row>
          <b-col xl="12">
            <b-card>
              <template v-slot:header>
                <!-- Subtitle -->
                <!-- <h6 class="surtitle">Overview</h6> -->
                <!-- Title -->
                <!-- <h5 class="h3 mb-0">Total sales</h5> -->
              </template>
              <div class="chart">
                <p>
                  Untuk mendownload buku Panduan Sparing silakan klik link ini

                  Tim SPARING Direktorat Pengendalian Pencemaran air JL.DI Panjaitan Kav. 24 Kebon Nanas Jakarta Timur Gedung B lantai 5 Email: sparing.menlhk@gmail.com Fathia Rizki 0813 10837646 ( hanya melayani via pesan WA/SMS, jam pelayanan hari kerja 08.00-15.00 WIB)
                </p>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </template>
  <script>
    import BaseHeader from '@/components/BaseHeader';
  
   
  
    export default {
      components: {
        BaseHeader,
      },
      data() {
        return {
          
        };
      }
    };
  </script>
  <style></style>
  